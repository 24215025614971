<template>
  <div >
    <div style="font-size: 36px;">{{$t('tools.a5')}}</div>
    <!-- search -->
    <div v-if="quanxian == 'Config' || quanxian == 'Admin'" class="flex" style=" margin: 30px 0;">
      <div style="width: 94%;" class="input-search">
        <div class="el-date-input" style="z-index: 100;right: 80px;opacity:0;">
          <el-select v-model="action" @change="getList" :placeholder="$t('common.a9')">
            <el-option
              v-for="(item,idx) in actionList"
              :key="idx"
              :label="$t(item.key)"
              :value="idx">
            </el-option>
          </el-select>
        </div>
        <div class="el-date-input" style="right: 80px;height: 40px;line-height: 40px;color: #9FA3AF;display: flex;font-size: 21px;background-color: #fff;">
          <div style="width: 1px;height: 27px;margin: 7px 10px 0 0;background: #9FA3AF;margin-right: 10px;"></div>
          <div style="margin-right: 10px">{{action > 0? $t(actionList[action].key) : $t('tools.a21')}}<i class="el-icon-caret-bottom"></i></div>
        </div>
        <div class="el-date-input" style="z-index: 100;opacity:0;">
          <el-date-picker
            style="width: 83px;"
            @change="getList"
            v-model="date"
            type="daterange"
            range-separator="至"
            :start-placeholder="$t('common.a10')"
            :end-placeholder="$t('common.a11')">
          </el-date-picker>
        </div>
        <div class="el-date-input" style="height: 40px;line-height: 40px;color: #9FA3AF;display: flex;font-size: 21px;background-color: #fff;">
          <div style="width: 1px;height: 27px;margin: 7px 10px 0 0;background: #9FA3AF;margin-right: 10px;"></div>
          <div style="margin-right: 10px">{{$t('param.a9')}}<i class="el-icon-caret-bottom"></i></div>
          
        </div>
        <input v-model="filename" type="text" class="search" :placeholder="$t('tools.a20')">
      </div>
      <div class="search-btn" @click="getList"><i class="el-icon-search"></i></div>
    </div>
    <!-- button -->
    <div class="tool-btn">
      <div style="margin-right: 20px;">
        <el-tag
          closable
          type="info"
          effect="plain"
          size="medium"
          @close="closeDate"
          @click="closeDate"
          v-if="date.length"
          >
          {{dateToString}}
        </el-tag>
      </div>
      <el-button v-if="quanxian == 'Admin'" size="large" @click="deleteAll">{{$t('common.a2')}}</el-button>
    </div>
    
    <el-table
      :data="tableData"
      class="table"
      border
      @selection-change="checkSelect"
      height="450"
      style="width: 100%;">
      <el-table-column
        type="selection"
        width="60">
      </el-table-column>
      <el-table-column
        type="index"
        :label="$t('param.number')"
        width="180">
      </el-table-column>
      <el-table-column
        prop="u_name" sortable
        :label="$t('tools.a22')">
      </el-table-column>
      <el-table-column
        prop="action" sortable
        width="180"
        :label="$t('tools.a21')">
      </el-table-column>
      <el-table-column
        prop="date" sortable
        width="180"
        :formatter="_formatDate"
        :label="$t('param.a9')">
      </el-table-column>
      <el-table-column
        prop="data" sortable
        width="180"
        :label="$t('param.data')">
      </el-table-column>
    </el-table>
    <div style="text-align: center;">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/http';
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      filename: '',
      action: 0,
      actionList: [{
        key: 'actionSelect.a1',
        value: '0'
      }, 
      {
        key: 'actionSelect.a2',
        value: 'Ajouter/Add'
      }, 
      {
        key: 'actionSelect.a3',
        value: 'Supprimer/delete'
      }, 
      {
        key: 'actionSelect.a4',
        value: 'Recherche/Query'
      }, 
      {
        key: 'actionSelect.a5',
        value: 'Édition/ edit'
      }, 
      {
        key: 'actionSelect.a6',
        value: 'Connexion/Log in'
      }, 
      {
        key: 'actionSelect.a7',
        value: 'Déconnecter/Log out'
      }],

      date: [],
      dateToString: '',

      tableData: [],
      deleteList: [],

      quanxian: ''
    }
  },
  created() {
    this.getList();
    sessionStorage.removeItem('file')
    sessionStorage.removeItem('edit')
    
    let user = sessionStorage.getItem('user');
    if(user) this.quanxian = JSON.parse(user).quanxian;
  },

  methods: {
    getList() {
      let params = {
        page: this.pageNum,
        page_size: this.pageSize,
        uid: sessionStorage.getItem('id'),

      }
      if(this.filename) {
        params.name = this.filename;
      }
      if(this.action && this.action != 0) {
        params.action = this.actionList[this.action].value;
      }
      if(this.date.length) {
        params.stime = Number((this.date[0].getTime()/1000).toFixed(0));
        params.etime = Number(((this.date[1].getTime()+86399999)/1000).toFixed(0));
        
        this.dateToString = new Date(this.date[0].getTime() + 3600000*8).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').substr(0,10) + " ~ " +
        new Date(this.date[1].getTime() + 3600000*8).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').substr(0,10)
      }
      this.postRequest('index/log_list', params, res => {
        this.tableData = res.data.info;
        this.total = res.data.count;
      }, true)
    },
    checkSelect(e) {
      this.deleteList = e.map(v => v.id)
    },
    deleteAll() {
      if(this.deleteList.length == 0) return ;
      // this.$confirm('是否删除选中的'+ this.deleteList.length+'条数据', this.$t('common.a2'), {
      this.$confirm(this.$t('common.a2')+'?', this.$t('message.a10'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        this.postRequest('index/del_log', {ids: this.deleteList.join(','),uid: sessionStorage.getItem('id')}, () => {
          this.$message({
            type: 'success',
            message: this.$t('message.a6')
          });
          this.getList();
        })
      })
    },
    
    closeDate() {
      this.date = [];
      this.dateToString = '';
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    _formatDate(row, col, value, index) {
      return formatDate(value);
    },
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;
 
}
.search:focus-visible {
  outline: none;
}
.search-btn {
  width: 6%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor:pointer;
  color: #C3D0FB;
  background: #5D80F5;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.tool-btn{
  display: flex;
  justify-content: flex-end;
}
.tool-btn >div {
  line-height: 44px;
}

.table {
  margin: 30px 0;
}


.input-search > .el-date-input {
  position: absolute;
  right: 0;
  top: 3px;
}
.input-search {
  position: relative;
  width: 94%;
}
</style>
